<template>
	<div class="p-1">
        <div class="flex justify-between mb-2">
            <span></span>
            <!-- <a-input placeholder="Buscar" v-model="query.search" @input="handleTypingSearch"/> -->
            <a-button :to="{ name: 'create_notification' }" v-if="!user.isStudent" class="w-full md:w-auto">
                Crear notificación
            </a-button>
        </div>

        <div class="flex justify-between flex-wrap my-4">
            <div class="flex justify-between">
                <h3 class="text-2xl">{{ displayingNotifications }}</h3>
            </div>
            <div class="flex" v-if="!user.isStudent">
                <a-segmented-control v-model="query.sent" :segments="segments" />
            </div>
        </div>

        <template v-if="loading">
            <a-skeleton class="h-24 w-full rounded-2xl" />
        </template>
        <template v-else-if="!loading && pagination.data.length">
            <div class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1  gap-4">
                <notification-card
                    @deleted="fetch"
                    v-for="(notification, key) in pagination.data"
                    @open-receivers="receivers = $event"
                    :notification="notification"
                    :key="key" />
            </div>
<!--            <a-laravel-pagination align="right" :data="pagination" @change="fetchNotifications({ ...query, page: $event })" class="mt-3"/>-->
        </template>
        <template v-else>
            <div class="mt-4">
                <h1 class="text-center text-2xl p-8">No hay notificaciones disponibles</h1>
            </div>
        </template>
        <a-modal
            title="Enviado a:"
            cencel-text="Cerrar"
            :show="showReceiversModal" @cancel="receivers = []">
            <ul>
                <li v-for="({ name, email, pivot, profile_picture }, key) in receivers" :key="key" class="space-y-2 border-b">
                    <div class="flex flew-wrap space-y-2 justify-between items-end py-4">
                        <div class="flex space-x-4 items-start">
                            <img v-if="profile_picture" class="w-16 h-16 shadow-md rounded-full ring-2 ring-gray-100" :src="profile_picture" alt="">
                            <span v-else class="w-16 h-16 text-2xl shadow-md rounded-full ring-2 ring-gray-100 bg-gradient-to-br from-blue-300 to-blue-600 flex justify-center items-center text-white">
                                {{ name | initials }}
                            </span>
                            <div class="flex items-start justify-start flex-col">
                                <p class="text-gray-800">{{ name }}</p>
                                <p class="text-gray-800">{{ email }}</p>
                            </div>
                        </div>
                        <p v-if="pivot.read_at" class="text-sm text-green-500">Leido el {{ pivot.read_at | date('YYYY-MM-DD [a las] HH:mm') }}</p>
                        <p v-else class="text-gray-500 text-sm">Nunca leido</p>
                    </div>
                </li>
            </ul>
        </a-modal>
<!--        <a-paginate-->
<!--            v-if="pagination.current_page"-->
<!--            :current="pagination.current_page"-->
<!--            :total="pagination.total"-->
<!--            @page-changed="fetch($event)"-->
<!--            :per-page="pagination.per_page" class="mt-3"/>-->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { notificationTypes } from '@/constants'
import NotificationCard from './components/notification-card'
export default {
    components: {
        NotificationCard
    },
    data: () => ({
        segments: [
            { id: 0, title: "Recibidas" },
            { id: 1, title: "Enviadas" },
        ],
        query: {
            limit: "12",
            search: '',
            page: 1,
            with: 'sender,receivers',
            sort_by: 'created_at,desc',
            sent: 0
        },
        timeout: null,
        loadingReads: [],
        receivers: []
    }),
    computed: {
        ...mapState({
            user: state => state.session.user,
            pagination: state => state.notifications.pagination,
            loading: state => state.notifications.loading
        }),
        showReceiversModal() {
            return this.receivers.length > 0
        },
        displayingNotifications() {
            if (this.query.sent === 1) {
                return 'Notificaciones enviadas'
            }

            return 'Notificaciones recibidas'
        }
    },
    methods: {
        mapReceivers({ item }) {
            let found = Object.entries(notificationTypes).map($0 => $0[1]).find($0 => $0.value === item.type)
            if (item.type === 7) {
                return item.receivers[0].email
            }
            return found.receiversText
        },
        ...mapActions({
            fetchNotifications: 'notifications/fetchAll',
            deleteNotification: 'notifications/delete',
        }),
        appendOrRemoveLoadingFor(type, id) {
            const loadings = this[`loading${type}`]
            if (loadings.includes(id)) {
                loadings.splice(loadings.indexOf(id), 1)
            } else {
                loadings.push(id)
            }
        },
        handleTypingSearch() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.fetchNotifications({ ...this.query, page: this.$route.query.page || 1 })
            }, 1000);
        },
        toggleQueryFilters(query) {

            var newQuery = { ...this.query, ...query }

            if (!query.sent) {
                delete newQuery.sent
            }

            var hasChanges = JSON.stringify(newQuery) !== JSON.stringify(this.$route.query)

            if (hasChanges) {
                this.$router.replace({ query: {...newQuery } })
                  .catch(() => null)
            }
        },
        fetch(page) {
            this.fetchNotifications({ ...this.query, page: page || 1 })
        }
    },
    watch: {
        'query.sent'() {
            this.fetchNotifications(this.query)
        }
    },
    mounted() {
        this.fetch()
    }
}
</script>
