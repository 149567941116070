<template>
    <div class="flex bg-white rounded-2xl p-6 shadow-md mt-2">
        <div class="flex w-full flex-wrap justify-center md:justify-start lg:justify-start">
            <div class="flex flex-col space-y-2 divide-y w-full">
                <div class="flex flex-col w-full">
                    <div class="flex flex-wrap space-x-4 justify-center space-y-4">
                        <!-- <img v-if="!notification.receivers" class="w-24 ring-2 shadow-lg ring-primary h-24 rounded-full" :src="notification.sender.profile_picture" alt=""> -->
                        <div class="flex flex-col justify-between items-start flex-grow">
                            <div class="text-left flex justify-between w-full">
                                <div v-if="notification.sender">
                                    <p class="text-lg">{{ notification.sender.name }}</p>
                                    <p class="text-gray-700 mb-4">{{ notification.sender.email }}</p>
                                </div>
                                <div v-else>
                                  <p class="text-lg mb-4">{{ notification.title }}</p>
                                </div>
                                <a-dropdown v-if="!disabledOptions.markAsRead || !disabledOptions.delete">
<!--                                    <button class="w-8 h-8 rounded-full border focus:outline-none focus:ring-2 focus:ring-primary">-->
<!--                                        <a-icon name="more" class="transform rotate-90" />-->
<!--                                    </button>-->
                                  <button class="w-8 h-8 rounded-full border focus:outline-none text-center focus:ring-2 focus:ring-primary">
                                    <a-icon name="more" class="block mx-auto transform rotate-90" />
                                  </button>
                                    <template #menu>
                                        <a-dropdown-item
                                            @click="markAsRead(notification.id)"
                                            v-if="notification.pivot && !notification.pivot.read_at && !disabledOptions.markAsRead"
                                            :disabled="loadings.markAsRead">

                                            Marcar como leida <a-loader v-if="loadings.markAsRead" />
                                        </a-dropdown-item>
                                        <a-dropdown-item
                                            @click="deleteNotification(notification.id)"
                                            v-if="!disabledOptions.delete"
                                            :disabled="loadings.delete">

                                            Eliminar <a-loader v-if="loadings.delete" />
                                        </a-dropdown-item>
                                    </template>
                                </a-dropdown>
                            </div>
                            <div class="text-left">
                                <p class="text-sm text-gray-600">
                                    <span class="block">{{ notification.receivers ? 'Enviado el' : 'Recibido el' }} {{ notification.created_at | date('YYYY-MM-DD [a las] HH:mm') }}</span>
                                    <template v-if="notification.pivot && notification.pivot.read_at">
                                        leido el {{ notification.pivot.read_at | date('YYYY-MM-DD [a las] HH:mm') }}
                                    </template>
                                    <template v-else-if="!notification.pivot && !notification.receivers">
                                        Nunca
                                    </template>
                                </p>
                            </div>
                        </div>
                    </div>
                    <template v-if="notification.receivers">
                        <p class="mt-4 text-sm text-gray-800">Enviado a:</p>
                        <div class="flex mt-2 items-end">
                            <template v-for="(receiver, key) in sortedReceivers(notification.receivers).slice(0, 10)">
                                <span v-tooltip="receiver.email" :key="key" class="bg-blue-400 rounded-full h-8 w-8 ring-2 ring-white -mr-3 cursor-pointer" v-if="!receiver.profile_picture" @click="openReceivers(notification.receivers)">
                                    <!-- <span>{{ receiver.email }}</span>{{ key === notification.receivers.length - 1 ? '.' : ',' }} -->
                                </span>
                                <img v-tooltip="receiver.email" v-else :key="key" :src="receiver.profile_picture" class="rounded-full h-8 w-8 ring-2 ring-white -mr-3 bg-gray-400 cursor-pointer" @click="openReceivers(notification.receivers)">
                            </template>
                            <template v-if="notification.receivers.length > 10">
                                <span class="inline-block ml-4 leading-none">... <span class="text-sm text-gray-500">{{ notification.receivers.length - 10 }} mas</span></span>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="text-left p-2 w-full">
                    <p class="text-justify">
                        {{ notification.content }}
                    </p>
                    <div v-if="notification.attachment" class="mt-4">
                        <p class="block text-gray-500 text-sm">Archivo adjunto</p>
                        <a class="text-blue-500" target="_blank" :href="notification.attachment">{{ notification.attachment.split('/').slice(-1).join() }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        notification: { required: true, type: Object },
        disabledOptions: { required: false, type: Object, default: () => ({}) }
    },
    data: () => ({
        loadings: {
            delete: false,
            markAsRead: false
        }
    }),
    methods: {
        deleteNotification(id) {
            this.$confirm({
                message: `¿Seguro que desea realizar esta acción?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.loadings.delete = !this.loadings.delete
                        this.$repository.notifications
                            .delete(id)
                            .then(() => {
                                this.$emit('deleted', id)
                            })
                            .finally(() => this.loadings.delete = !this.loadings.delete)
                    }
                }
            })
        },
        sortedReceivers(receivers) {
            return [...receivers].sort((a, b) => {
                return a.profile_picture && b.profile_picture ? -1 : 1
            })
        },
        openReceivers(receivers) {
            const payload = [...receivers].sort((a, b) => {
                return a.pivot.read_at === b.pivot.read_at && (a.pivot.read_at === null && b.pivot.read_at === null) ? -1 : a.pivot.read_at > b.pivot.read_at ? 1 : -1
                // a.name === b.name ? 0 : a.name < b.name ? -1 : 1
            })

            this.$emit('open-receivers', payload)
        },
        markAsRead(id) {
            this.loadings.markAsRead = !this.loadings.markAsRead
            this.$repository.notifications
                .markAsRead(id)
                .then(() => {
                    this.fetchNotifications({ ...this.query, page: this.$route.query.page || 1 })
                })
                .finally(() => this.loadings.markAsRead = !this.loadings.markAsRead)
        },
    }
}
</script>
